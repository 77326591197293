import React from 'react';
import { useLanguage } from './LangProvider';

const LanguageSwitcher = () => {
    const { changeLanguage, currentLanguage } = useLanguage();

    const handleLanguageChange = (lang) => {
        changeLanguage(lang);
    };

    return (
        <div className="language-wrapper">
            <div className='dekstop'>
                <span
                    onClick={() => handleLanguageChange('en')}
                    style={{
                        cursor: 'pointer',
                        fontWeight: currentLanguage === 'en' ? 'bold' : 'normal',
                        color: currentLanguage === 'en' ? '#3d60a2' : '#75b4e7',
                        padding: '5px',
                    }}
                >
                    EN
                </span>
                <span style={{color:'#3d60a2', margin: '0px 2px'}}>|</span>
                <span
                    onClick={() => handleLanguageChange('id')}
                    style={{
                        cursor: 'pointer',
                        fontWeight: currentLanguage === 'id' ? 'bold' : 'normal',
                        color: currentLanguage === 'id' ? '#3d60a2' : '#75b4e7',
                        padding: '5px',
                    }}
                >
                    ID
                </span>
            </div>
            <div className='mobile'>
            <span
                    onClick={() => handleLanguageChange('en')}
                    style={{
                        cursor: 'pointer',
                        fontWeight: currentLanguage === 'en' ? 'bold' : 'normal',
                        color: currentLanguage === 'en' ? '#ffffff' : '#ffffff',
                        opacity: currentLanguage === 'en' ? '1' : '0.4',
                        padding: '5px',
                    }}
                >
                    EN
                </span>
                <span style={{color:'#fff', display: 'inline-block', verticalAlign: '2px', fontSize: '17px', margin: '0px 4px'}}>|</span>
                <span
                    onClick={() => handleLanguageChange('id')}
                    style={{
                        cursor: 'pointer',
                        fontWeight: currentLanguage === 'id' ? 'bold' : 'normal',
                        color: currentLanguage === 'id' ? '#ffffff' : '#ffffff',
                        opacity: currentLanguage === 'id' ? '1' : '0.4',
                        padding: '5px',
                    }}
                >
                    ID
                </span>
            </div>
        </div>

        
    );
};

export default LanguageSwitcher;